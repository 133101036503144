import React, { Component } from "react";
import { doHttpGet, doHttpPost } from "../../services/WebService";
import { healthcareFinanceEditUrl, healthcareFinanceSaveUrl } from "../../library/Urls";
import Modal from "react-bootstrap/Modal";
import Checkbox from "../common/Checkbox";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { isValidUsMoney, numberToFixedOrZero } from "../../library/Utilities";
import NumberField from "../common/NumberField";

export default class EditFinancialAccount extends Component {
  constructor(props) {
    super(props);
    // Initial state
    this.state = {
      show: props.show,
      onClose: props.handleClose,
      onSave: props.handleSave,
      financeId: props.financeId == null ? null : parseInt(props.financeId),
      errorMessage: null,
      actionsEnabled: false,
      onError: props.showErrorMessage,
    };
  }

  componentDidMount = (evt) => {
    // Initial data
    doHttpGet({
      url: healthcareFinanceEditUrl,
      params: {
        id: this.state.financeId,
      },
      onSuccess: (data) => {
        this.setState({
          editInfo: {
            ...data,
            initial_amount: numberToFixedOrZero(data.initial_amount, 2),
            issuer: data.issuer || "",
          },
          actionsEnabled: true,
          errorMessage: null,
        });
      },
      onError: (error) => {
        this.setState({
          errorMessage: error,
        });
        this.state.onError(error);
      },
    });
  };

  validateFinancialAccount = (values) => {
    // Errors
    const errors = {};
    // Name
    if (!values.account_name) {
      errors.account_name = "Account name is required";
    }
    // Issuer
    if (!values.issuer) {
      errors.issuer = "Issuer/employer is required";
    }
    // Number
    if (!values.account_number) {
      errors.account_number = "Account number is required";
    }
    // Initial amount
    if (!values.initial_amount) {
      errors.initial_amount = "Initial amount is required";
    } else {
      if (!isValidUsMoney(values.initial_amount)) {
        errors.initial_amount = "Invalid monetary amount";
      }
    }
    // Statement frequency
    if (!values.reconciliation_freq) {
      errors.reconciliation_freq = "Statement frequency is required";
    }
    // Whether any errors
    let hasErrors = false;
    for (const attr in errors) {
      if (errors.hasOwnProperty(attr)) {
        hasErrors = true;
        break;
      }
    }
    if (!hasErrors) {
      // Save form values in state
      this.setState({
        HealthcareAcctInfo: values,
        hasValidationErrors: false,
        actionsEnabled: true,
      });
    } else {
      this.setState({
        hasValidationErrors: true,
        actionsEnabled: false,
      });
    }
    // Errors
    return errors;
  };

  saveFinancialAccount = () => {
    // Disable actions
    this.setState({
      actionsEnabled: false,
    });
    // Build request
    const requestInfo = {
      id: this.state.financeId,
      ...this.state.HealthcareAcctInfo,
    };
    // Post
    doHttpPost({
      url: healthcareFinanceSaveUrl,
      body: requestInfo,
      onSuccess: (response) => {
        this.state.onSave(response.id);
      },
      rawError: true,
      onError: (error) => {
        this.setState({
          actionsEnabled: true,
        });
        const httpStatus = error.response.status;
        if (httpStatus === 400) {
          let htmlError = "<ol>";
          error.response.data.errors.forEach((e) => {
            htmlError += "<li>" + e.field + ": " + e.defaultMessage + "</li>";
          });
          htmlError += "</ol>";
          this.setState({
            errorMessage: htmlError,
          });
        } else if (httpStatus === 500) {
          const errorMessage = error.response.data.message;
          this.setState({
            errorMessage: errorMessage,
          });
        }
      },
    });
  };

  render() {
    // Info
    const editInfo = this.state.editInfo;
    if (!editInfo) return null;
    // Whether editing
    const isEditing = this.state.financeId != null;
    // Form field values
    const { account_name, account_number, initial_amount, is_hsa, is_enabled, for_transactions, issuer, reconciliation_freq } = editInfo;
    // Render
    return (
      <Modal
        show={this.state.show}
        onHide={this.state.onClose}
        centered
        size="sm"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {isEditing ? "Update" : "Create"} Financial Account
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.errorMessage && (
            <div
              className="alert alert-warning"
              style={{
                marginBottom: "10px",
              }}
            >
              {this.state.errorMessage}
            </div>
          )}
          <Formik
            initialValues={{ account_name, account_number, initial_amount, issuer, is_hsa, is_enabled, for_transactions, reconciliation_freq }}
            validate={this.validateFinancialAccount}
            validateOnBlur={true}
            validateOnChange={true}
            enableReinitialize={true}
          >
            {(props) => {
              return (
                  <Form>
                    <fieldset className="form-group required">
                      <label className="control-label">Account Name</label>
                      <Field
                          type="text"
                          name="account_name"
                          className="form-control"
                      ></Field>
                      <ErrorMessage
                          name="account_name"
                          className="alert alert-warning"
                          component="div"
                      />
                    </fieldset>
                    <fieldset className="form-group required">
                      <label className="control-label">Issuer/Employer</label>
                      <Field
                          type="text"
                          name="issuer"
                          className="form-control"
                      ></Field>
                      <ErrorMessage
                          name="issuer"
                          className="alert alert-warning"
                          component="div"
                      />
                    </fieldset>
                    <fieldset className="form-group required">
                      <label className="control-label">Account Number</label>
                      <Field
                          type="text"
                          name="account_number"
                          className="form-control"
                      ></Field>
                      <ErrorMessage
                          name="account_number"
                          className="alert alert-warning"
                          component="div"
                      />
                    </fieldset>
                    <fieldset className="form-group required">
                      <label className="control-label">Initial Amount</label>
                      <Field component={NumberField} name="initial_amount"></Field>
                      <ErrorMessage
                          name="initial_amount"
                          className="alert alert-warning"
                          component="div"
                      />
                    </fieldset>
                    <fieldset className="form-group required">
                      <label className="control-label">
                        Statements Frequency (Months)
                      </label>
                      <Field
                          component={NumberField}
                          integerOnly={true}
                          allowDecimals={false}
                          name="reconciliation_freq"
                      ></Field>
                      <ErrorMessage
                          name="reconciliation_freq"
                          className="alert alert-warning"
                          component="div"
                      />
                    </fieldset>
                    <fieldset className="form-group required">
                      <Field
                          component={Checkbox}
                          name="is_hsa"
                          id="is_hsa"
                          label="This is an HSA card"
                      />
                    </fieldset>
                    <fieldset className="form-group required">
                      <Field
                          component={Checkbox}
                          name="is_enabled"
                          id="is_enabled"
                          label="Account is enabled/active"
                      />
                    </fieldset>
                    <fieldset className="form-group required">
                      <Field
                          component={Checkbox}
                          name="for_transactions"
                          id="for_transactions"
                          label="Account can be used for transactions"
                      />
                    </fieldset>
                  </Form>
              );
            }}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
                type="button"
                className="btn btn-md btn-primary"
                disabled={
                    !this.state.actionsEnabled || this.state.hasValidationErrors
                }
                onClick={this.saveFinancialAccount}
            >
              Save Financial Account
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}
