import React, { useState, useEffect } from "react";
import { doHttpGet } from "../../services/WebService";
import { designationInfoUrl } from "../../library/Urls";
import Money from "../common/Money";
import { Link } from "react-router-dom";
import * as Links from "../../library/Links";
import { fmtMoneyUSD } from "../../library/Utilities";
import { Tabs, Tab } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRandom } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import TransferDesignation from "./TransferDesignation";
import classnames from "classnames";

const DesignationsInfo = () => {
  // State
  const [designationInfo, setDesignationInfo] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isShowDesignationTransferDlg, showDesignationTransferDlg] =
    useState(false);
  const [selectedFinanceId, setSelectedFinanceId] = useState(null);
  const [selectedDesignationId, setSelectedDesignationId] = useState(null);

  // Changes
  useEffect(() => {
    // Designation info
    doHttpGet({
      url: designationInfoUrl,
      onSuccess: (info) => {
        setErrorMessage(null);
        setDesignationInfo(info);
      },
      onError: (error) => setErrorMessage(error),
    });
  }, [isShowDesignationTransferDlg]);

  // Category
  let currentCategory = null;

  // Totals
  let dcCountTotal = 0;
  let dcAmountTotal = 0.0;

  // Transfer designation dialog
  const dlgDesignationTransfer = (financeId, designationId) => {
    // Required data
    setSelectedDesignationId(designationId);
    setSelectedFinanceId(financeId);
    // Open dialog
    showDesignationTransferDlg(true);
  };

  const refreshDesignationInfo = () => {
    // Close dialog
    showDesignationTransferDlg(false);
  };

  // Render
  return (
    <div className="page-content">
      {errorMessage && (
        <div
          className="alert alert-danger"
          style={{
            marginBottom: "10px",
            fontWeight: "bold",
            fontSize: "1.2em",
            padding: "10px",
          }}
        >
          {errorMessage}
        </div>
      )}

      {designationInfo && (
        <div>
          <div className="row" style={{ marginBottom: 10 }}>
            <div className="col-4">
              <h2>Designations</h2>
            </div>
            <div className="col-4">
              <table
                className="table table-sm table-striped tbl-details"
                style={{ marginBottom: 0 }}
              >
                <tbody>
                  <tr>
                    <td className="label-no-width">
                      Total Designation Types :
                    </td>
                    <td>{designationInfo.designationTypeCount}</td>
                  </tr>
                  <tr>
                    <td className="label-no-width">Number of Designations :</td>
                    <td>{designationInfo.designationCount}</td>
                  </tr>
                  <tr>
                    <td className="label-no-width">
                      Total Financial Balance :
                    </td>
                    <td>{fmtMoneyUSD(designationInfo.totalFinancialAmount)}</td>
                  </tr>
                  <tr>
                    <td className="label-no-width">
                      Number of Financial Accounts :
                    </td>
                    <td>{designationInfo.financialsCount}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-4 text-right">
              <Money
                amount={designationInfo.totalDesignationAmount}
                className="amount-lg"
              />
            </div>
          </div>

          <Tabs defaultActiveKey="designations" id="designations-tab">
            <Tab
              eventKey="designations"
              title={
                "Active Designations (" + designationInfo.designationCount + ")"
              }
            >
              <table className="table table-md table-striped tbl-designations table-bordered">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col" className="col-3">
                      Description
                    </th>
                    <th scope="col" className="col-1">
                      Amount
                    </th>
                    <th scope="col" className="col-8">
                      Financial Accounts
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {designationInfo.designations.map((designation) => {
                    // Description
                    const description = designation.description;
                    let isLinkDescription =
                      designation.type.match(/Budget|Savings Goal/);
                    let descriptionUrl = null;
                    if (isLinkDescription) {
                      if (designation.type === "Budget") {
                        descriptionUrl = Links.budgetFor(
                          designation.designationReferenceId
                        );
                      } else if (designation.type === "Savings Goal") {
                        descriptionUrl = Links.savingsGoalFor(
                          designation.designationReferenceId
                        );
                      } else {
                        isLinkDescription = false;
                      }
                    }
                    // Financial accounts
                    const financialAccounts = designation.financials;
                    // Category
                    const addCategoryRow = designation.type !== currentCategory;
                    let categoryItemCount = 0;
                    if (addCategoryRow) {
                      currentCategory = designation.type;
                      categoryItemCount = designationInfo.designations.reduce(
                        function (n, d) {
                          return n + (d.type === currentCategory);
                        },
                        0
                      );
                    }
                    // Rows
                    const tableRows = [];
                    if (addCategoryRow) {
                      tableRows.push(
                        <tr key={"section-" + currentCategory}>
                          <th
                            colSpan={3}
                            style={{
                              fontSize: "1.1em",
                              backgroundColor: "darkgray",
                              textTransform: "uppercase",
                            }}
                          >
                            {currentCategory} ({categoryItemCount})
                          </th>
                        </tr>
                      );
                    }
                    tableRows.push(
                      <tr key={"d-" + designation.id}>
                        <td>
                          {isLinkDescription ? (
                            <Link to={descriptionUrl}>{description}</Link>
                          ) : (
                            description
                          )}
                        </td>
                        <td className="text-right">
                          <Money amount={designation.amount} />
                        </td>
                        <td>
                          <table
                            className="table table-sm"
                            style={{ marginBottom: "0" }}
                          >
                            <tbody>
                              {financialAccounts.map((finance) => (
                                <tr key={"fa-" + finance.id}>
                                  <td
                                    className="text-right col-2"
                                    style={{ paddingRight: "15px" }}
                                  >
                                    <Money amount={finance.amount} />
                                  </td>
                                  <td
                                    className="text-right col-2"
                                    style={{ paddingRight: "15px" }}
                                  >
                                    {finance.lastUpdated}
                                  </td>
                                  <td className="col-7">
                                    <Link to={Links.financeFor(finance.id)}>
                                      {finance.name}
                                    </Link>
                                    <span
                                      style={{
                                        color: "gray",
                                        marginLeft: "10px",
                                      }}
                                    >
                                      {finance.description}
                                    </span>
                                  </td>
                                  <td
                                    className="text-right col-1"
                                    style={{ padding: "5px" }}
                                  >
                                    <Button
                                      className="btn btn-sm"
                                      onClick={() =>
                                        dlgDesignationTransfer(
                                          finance.id,
                                          designation.id
                                        )
                                      }
                                    >
                                      <FontAwesomeIcon icon={faRandom} />
                                    </Button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    );
                    // Render
                    return tableRows.map((row) => row);
                  })}
                </tbody>
              </table>
            </Tab>
            <Tab eventKey="summary" title="Designation Summary">
              <div className="row">
                <div className="col-4">
                  <h3>Designation Categories</h3>
                  <table className="table table-md table-striped table-bordered">
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col" className="text-right">
                          Count
                        </th>
                        <th scope="col" className="text-right">
                          Amount
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {designationInfo.designationTypes.map((dt) => {
                        dcCountTotal += dt.count;
                        dcAmountTotal += dt.amount;
                        return (
                          <tr key={"dt-" + dt.id}>
                            <td>{dt.name}</td>
                            <td className="text-right">{dt.count}</td>
                            <td className="text-right">
                              <Money amount={dt.amount} />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                    <tfoot className="table-footer">
                      <tr>
                        <th className="text-right">TOTALS:</th>
                        <th className="text-right">{dcCountTotal}</th>
                        <th className="text-right">
                          <Money amount={dcAmountTotal} />
                        </th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <div className="col-8">
                  <h3>Financial Accounts</h3>
                  <table className="table table-md table-striped table-bordered">
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Description</th>
                        <th scope="col" className="text-right">
                          Balance
                        </th>
                        <th scope="col" className="text-right">
                          Designations
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {designationInfo.designationFinances.map((df) => {
                        return (
                          <tr key={"df-" + df.id} className={classnames("", {
                            focus: df.balance !== df.amount
                          })}>
                            <td>
                              <Link to={Links.financeFor(df.id)}>
                                {df.name}
                              </Link>
                              <span
                                style={{
                                  color: "gray",
                                  marginLeft: "10px",
                                }}
                              >
                                {df.description}
                              </span>
                            </td>
                            <td className="text-right">
                              <Money amount={df.balance} />
                            </td>
                            <td className="text-right">
                              <Money amount={df.amount} />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      )}

      {isShowDesignationTransferDlg && (
        <TransferDesignation
          show={isShowDesignationTransferDlg}
          onClose={() => showDesignationTransferDlg(false)}
          onTransfer={refreshDesignationInfo}
          financeId={selectedFinanceId}
          designationId={selectedDesignationId}
        />
      )}
    </div>
  );
};

export default DesignationsInfo;
