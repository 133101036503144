import React, {Fragment, useEffect, useState} from "react";
import {fmtDate, fmtMoneyUSD} from "../../library/Utilities";
import * as Links from "../../library/Links";
import {Link} from "react-router-dom";
import classnames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";

const HealthcareDesignations = ({
    designations,
}) => {
    // State
    const [_totalBalanceAmt, setTotalBalanceAmt] = useState(0.0);
    const [_totalDesignatedAmt, setTotalDesignatedAmt] = useState(0.0);

    // Total balance and designation amounts
    let balanceAmt = 0.0, designatedAmt = 0.0;
    designations.forEach(d => {
        balanceAmt += d.finance_balance;
        designatedAmt += d.designated_amount;
    });

    // Changes
    useEffect(() => setTotalBalanceAmt(balanceAmt), [_totalBalanceAmt]);
    useEffect(() => setTotalDesignatedAmt(designatedAmt), [_totalDesignatedAmt]);

    return (
        <Fragment>
            <table className="table table-md table-striped table-bordered">
                <thead className="thead-dark">
                <tr>
                    <th scope="col">Financial Account</th>
                    <th scope="col">Enabled</th>
                    <th scope="col" className="text-right">Balance</th>
                    <th scope="col" className="text-right">Designated</th>
                    <th scope="col">Last Updated</th>
                </tr>
                </thead>
                <tbody>
                {designations.map((d) => {
                    // Render
                    return (
                        <tr
                            key={"designation-" + d.id}
                            className={classnames("", {
                                "tbl-row-disabled": !d.finance_enabled,
                            })}
                        >
                            <td>
                                <Link to={Links.financeAccount + `/${d.finance_id}`}>{d.finance_name}</Link> | {d.finance_description}
                            </td>
                            <td className="check">
                                {d.finance_enabled && <FontAwesomeIcon icon={faCheck}/>}
                            </td>
                            <td className="amount text-right">{fmtMoneyUSD(d.finance_balance)}</td>
                            <td className="amount text-right">{fmtMoneyUSD(d.designated_amount)}</td>
                            <td>{fmtDate(d.last_updated_date)}</td>
                        </tr>
                    );
                })}
                </tbody>
                <tfoot className="table-footer">
                <tr>
                    <th className="text-right">Totals</th>
                    <th>&nbsp;</th>
                    <th className="text-right">{fmtMoneyUSD(_totalBalanceAmt)}</th>
                    <th className="text-right">{fmtMoneyUSD(_totalDesignatedAmt)}</th>
                    <th>&nbsp;</th>
                </tr>
                </tfoot>
            </table>
        </Fragment>
    );
};

export default HealthcareDesignations;